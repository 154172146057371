import React from "react"

import Layout from "components/Layout/de"
import { Container, Row, Col } from "react-bootstrap"

import SupporterLogos from "components/OurPartnersSection/SupporterLogos"

// Import images
import teamGroupImg from "./moonteam_upper_body.jpg"

export default function(props) {
  const header = {
    text: "Wir lehren Maschinen die Welt zu sehen",
    description:
      "Unsere MoonVision Toolbox ermöglicht es Domain-Experten auf der ganzen Welt, die Prozesse in ihrer Verantwortung besser zu verstehen, zu automatisieren und zu überprüfen.",
    image: teamGroupImg,
  }
  return (
    <Layout header={header} location={props.location}>
      <Container>
        <Row className="py-5">
          <Col className="col-md-8 offset-md-2">
            <h2>
              Wir sind ein Team aus AI ExpertInnen, die schon heute zukünftige
              Herausforderungen lösen.
            </h2>
            <br />
            <p>
              KI ist das Instrumentarium für die Arbeitskraft von morgen, das
              uns erlaubt, jeden Aspekt unseres Lebens zu verbessern und um die
              großen Herausforderungen zu lösen, welche vor uns liegen.
              Maschinen werden in der Lage sein, ihre Umgebung zu sehen und zu
              verstehen und um, zu unserem Vorteil, autonomer zu arbeiten. In
              diesem Prozess wird die Beziehung zwischen Mensch und Maschine
              gestärkt und zu mehr Interaktionen zwischen diesen Parteien
              geführt.
            </p>
            <p>
              Bei MoonVision bieten wir ein Toolset für Domain-Experten an, um
              Maschinen zur visuellen Oberflächenüberprüfung zu unterrichten.
              Wir helfen ihnen, eine bessere Qualitätssicherung und
              Prozesssicherheit in ihrer Verantwortung zu gewährleisten, um die
              Fehlerwahrscheinlichkeit auf Null zu reduzieren. Die beste
              Vorbereitung für die Arbeitskraft von morgen besteht darin, ihnen
              die Werkzeuge in die Hand zu geben, um die Kontrolle zu behalten.
            </p>
          </Col>
        </Row>
      </Container>
      <SupporterLogos />
    </Layout>
  )
}
